<template>
  <div>
      <div v-if="fileURI">
        <!-- <VueDocPreview :url="fileURI" :type="type" /> -->
      </div>
       <!-- <p>{{fileURI}}</p> -->
  </div>
</template>

<script>
//import VueDocPreview from 'vue-doc-preview'
export default {
data () {
    return {
        type: 'office',
        fileURI: null, //'https://www.learningcontainer.com/wp-content/uploads/2020/04/sample-text-file.txt',

    }
},
components: {
  //  VueDocPreview
},
created (){
    this.getView()
    if (this.$route.query.fid) {
        //this.type = this.$route.query.doctype
        this.openFile(this.$route.query.fid)
    } else 
    {
        alert('No fileID presented!!!')
    }
    
},
methods: {
    getView() {
        this.$http.get('/ViewAccess/docsdocumentviewer')
        .then(() => {
        })
        .catch(() => {
        })
    },
    openFile(id) {
        this.$http.get('/docs/getFile/' + id, { 'responseType': 'blob' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', response.headers.filename)
          this.fileURI = fileURL
          //document.body.appendChild(fileLink)
          //fileLink.click()
        })
        .catch((error) => {
            if (error != null) {
                this.$message.error('There was an error fetching this file')
            } else {
                this.$message.error('There was an error fetching this file')
            }
        })
        },
}
}
</script>

<style>

</style>